var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.SHOWS_FOOTER)?_c('div',{staticClass:"csn-footer",class:{ 'csn-footer-five': _vm.isCasinoFive }},[_c('section',{staticClass:"background background-default"},[_c('div',{staticClass:"container csn-footer-container"},[_c('div',{staticClass:"row homepage-cms-list csn-footer-row"},_vm._l((_vm.csmDictionary),function(ref,index){
var title = ref.title;
var items = ref.items;
return _c('div',{key:index,staticClass:"col-md-3"},[_c('h4',[_vm._v(_vm._s(title))]),(items)?_c('ul',{staticClass:"page-list csn-footer-col-list"},_vm._l((items),function(ref,index){
var itemTitle = ref.title;
var remoteLink = ref.remoteLink;
var routePath = ref.routePath;
var urlSlug = ref.urlSlug;
return _c('li',{key:index},[(routePath)?_c('Route',{attrs:{"to":("" + _vm.locale + routePath)}},[_vm._v(" "+_vm._s(itemTitle)+" ")]):(urlSlug)?_c('Route',{attrs:{"to":{ name: _vm.route.support, params: { slug: urlSlug } }}},[_vm._v(" "+_vm._s(itemTitle)+" ")]):(remoteLink)?_c('a',{attrs:{"href":remoteLink}},[_vm._v(_vm._s(itemTitle))]):_c('span',[_vm._v(_vm._s(itemTitle))])],1)}),0):_vm._e()])}),0)])]),_c('section',{staticClass:"background csn-footer-img-container"},[_c('div',{staticClass:"csn-footer-game-vendor-list"},_vm._l((_vm.vendorLogoList),function(ref,index){
var logoUrl = ref.logoUrl;
var slug = ref.slug;
var name = ref.name;
return _c('Route',{key:index,staticClass:"vendor-name csn-footer-vendor-name",attrs:{"to":{ name: _vm.route.gameVendor, params: { slug: slug } },"title":name}},[_c('img',{staticClass:"csn-footer-vendor-img",attrs:{"src":logoUrl,"alt":name}})])}),1)]),(_vm.IS_GAME_VENDOR_PENDING)?_c('Loader'):_c('section',{staticClass:"background background-default"},[_c('div',{staticClass:"container csn-footer-container csn-footer-container-vendor"},[_c('div',{staticClass:"homepage-vendor-section csn-footer-row csn-footer-row-vendor"},[_c('ul',{staticClass:"homepage-vendor-list csn-footer-vendor-list"},_vm._l((_vm.GAME_VENDOR_DICTIONARY),function(ref,slug){
var name = ref.name;
var games = ref.games;
return _c('li',{key:name,staticClass:"homepage-vendor-list-game csn-footer-vendor"},[_c('div',{class:_vm.getVendorClass(slug),on:{"click":function($event){return _vm.selectVendor(slug)}}},[(_vm.isMobileScreen)?_c('span',{staticClass:"csn-footer-vendor-name-mobile"},[_vm._v(" "+_vm._s(name)+" ")]):_c('Route',{staticClass:"vendor-name csn-footer-vendor-name",attrs:{"to":{ name: _vm.route.gameVendor, params: { slug: slug } }}},[_vm._v(" "+_vm._s(name)+" ")])],1),_c('ul',{class:{
                'csn-footer-vendor-game-list': _vm.selectedVendor === slug,
              }},_vm._l((games),function(game,slug){return _c('li',{key:slug},[_c('Route',{attrs:{"to":{ name: _vm.route.gameInfo, params: { slug: slug } }}},[_vm._v(" "+_vm._s(game.name)+" ")])],1)}),0)])}),0)])])]),_c('section',{staticClass:"background csn-footer-img-container"},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"text-center"},_vm._l((_vm.paymentProviderList),function(ref){
              var logoUrl = ref.logoUrl;
              var name = ref.name;
return _c('img',{key:name,staticClass:"csn-footer-payment-img",attrs:{"src":logoUrl,"alt":name}})}),0),_c('div',{staticClass:"csn-footer-payment-img-lower-container"},[_c('Route',{attrs:{"to":{
            name: _vm.route.support,
            params: {
              slug: 'responsible-gaming',
            },
          }}},[(_vm.isCasinoFive)?_c('div',{staticClass:"csn-footer-age-limit-img-container"},[_c('img',{attrs:{"src":require("@/assets/images/themes/casino-five/18_plus.svg"),"alt":"18_plus"}}),_c('img',{attrs:{"src":require("@/assets/images/themes/casino-five/red_hand.svg"),"alt":"red_hand"}})]):_c('div',{staticClass:"csn-footer-18-plus-img"})])],1)])]),_c('section',[_c('div',{staticClass:"container csn-footer-container csn-footer-container-description"},[_c('div',{staticClass:"homepage-page-description csn-footer-row"},[_c('h2',[_vm._v(_vm._s(_vm.t('text_1')))]),_c('p',[_vm._v(_vm._s(_vm.t('description_1')))]),_c('h2',[_vm._v(_vm._s(_vm.t('text_2')))]),_c('p',[_vm._v(_vm._s(_vm.t('description_2')))]),_c('h2',[_vm._v(_vm._s(_vm.t('text_3')))]),_c('p',[_vm._v(_vm._s(_vm.t('description_3')))]),_c('h2',[_vm._v(_vm._s(_vm.t('text_4')))]),_c('p',[_vm._v(_vm._s(_vm.t('description_4_paragraph_1')))]),_c('p',[_vm._v(_vm._s(_vm.t('description_4_paragraph_2')))])]),_c('div',{staticClass:"bt-1 csn-footer-row csn-footer-border"}),_c('div',{staticClass:"row csn-homepage-page-footer csn-footer-row"},[_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v(_vm._s(_vm.t('footer_left_text')))]),_c('p',[_vm._v(_vm._s(_vm.t('footer_left_ending')))]),_c('p',[_c('a',{attrs:{"href":_vm.mgaLink}},[_c('img',{attrs:{"src":require("@/assets/images/mga-icon.png"),"alt":"mga"}})])])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v(_vm._s(_vm.t('footer_right_text')))]),_c('p',[_vm._v(" "+_vm._s(_vm.t('footer_right_ending'))+" ")]),_vm._m(0)])])])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"csn-footer-links"},[_c('li',[_c('a',{attrs:{"href":"https://www.begambleaware.org/"}},[_vm._v(" begambleaware.org ")])]),_c('li',[_c('a',{attrs:{"href":"https://www.gamcare.org.uk/"}},[_vm._v("GameCare")])]),_c('li',[_c('a',{attrs:{"href":"https://www.gamblersanonymous.org/ga/"}},[_vm._v(" Gameblers Anonymous ")])])])}]

export { render, staticRenderFns }